.technologies {
    //margin-bottom: calc(60px + (220 - 60) * ((100vw - 320px) / (1200 - 320)));
    background: #ffffff;
    padding: 25px;
    border-radius: 11px;

    // .technologies__title
    &__title {
        font-family: ProbaPro;
        font-weight: 600;
        font-size: rem(36);
        text-align: center;
        color: #202020;
        margin-bottom: 20px;
        @media (max-width: 1200px) {
            //font-size: calc(30px + (36 - 30) * ((100vw - 320px) / (1200 - 320)));
        }
    }
    // .technologies__list
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    img {
        max-height: 70px;
        border-radius: 5px;
    }
}