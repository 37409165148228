.footer {
    //min-height: 431px;
    //padding: 0 20px;
    //margin-bottom: 50px;
    // .footer__info
    &__info {
    }
}
.info {
    // .info__logo
    &__logo {
        display: inline-block;
        padding: 17.8px 0px;
        margin-bottom: 25px;
        img {
            max-width: 100%;
            max-height: 88px;
            color: #11265b;
        }
        @media (max-width: 768px) {
            margin-bottom: 0px;
        }
    }
    // .info__text
    &__text {
        font-family: "Source Code Pro";
        font-weight: normal;
        font-size: rem(24);
        color: #202020;
        @media (max-width: 1200px) {
            //font-size: calc(19px + (24 - 19) * ((100vw - 320px) / (1200 - 320)));
        }
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
    }
}

.footer-nav {
    // .footer-nav__nav
    &__nav {
        margin-bottom: 20px !important;
        display: flex;
        align-items: center;
        min-height: 131px; //186px;
        font-family: ProbaPro;
        font-weight: 400;
        font-size: rem(24);
        transition: all 0.3s ease 0s;
        @media (max-width: 1200px) {
            //font-size: calc(19px + (24 - 19) * ((100vw - 320px) / (1200 - 320)));
        }
        @media (max-width: 767px) {
            display: none;
        }
        // .nav__item

        @media (max-width: 1050px) {
            li {
                padding: 0 6px;
            }
        }

        a {
            padding: 5px 30px 5px 0!important;
            //text-align: center;
            //color: #202020;
            &:hover {
                text-decoration: underline;
                color: #002366;
                transition: all 0.3s ease 0s;
            }
        }
    }
    // .footer-nav__promo
    &__promo {
    }
    // .footer-nav__title
    &__title {
        font-family: "Source Code Pro";
        font-weight: 600;
        font-size: rem(24);
        line-height: 30px;
        color: #000000;
        margin: 0 0 10px 0;
    }
    // .footer-nav__link
    &__link {
        display: block;
        font-family: ProbaPro;
        font-weight: normal;
        font-size: 18px;
        color: #000000;
        margin: 0 0 11px 0;
        &:hover {
            &:hover {
                text-decoration: underline;
                color: #002366;
            }
            &:active {
                text-decoration: underline;
                color: #f70808;
            }
        }
    }
}
