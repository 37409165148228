.clients {
    background: #ffffff;
    /*    
    border-radius: 21px;
    padding: 15px 10px;
    // .clients__title
    &__title {
        font-size: rem(36);
        @media (max-width: 576px) {
            margin-bottom: 10px;
        }
    }
    // .clients__list
    &__list {
        li {
            text-align: center;
            img {
                max-width: 100%;
            }
        }
    }*/

    .box__slider {
        li {
            margin: 0 15px;
            //padding: 10px;
            width: 375px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 768px) {
                width: 100%;
            }
            img {
                padding: 8px;
            }
            &:nth-child(5) {
                img {
                    padding: 15px;
                    //background: #1c1c1c;
                }
            }
        }
        img {
            //max-width: 140px;
            height: 70px;
        }
        span {
            max-width: 200px;
            font-family: ProbaPro;
            //font-size: 13px;
            font-weight: bold;
            padding-left: 10px;
            text-align: left;
            text-transform: uppercase;
            @media (max-width: 768px) {
                //display: none;
            }
        }
    }
}
