.about {
    //min-height: (100vh);
    // .about__nav
    &__nav {
        margin-bottom: 72px !important;
    }
    // .about__title
    &__title {
        margin-bottom: 22px;
    }
    // .about__button
    &__button {
        width: 100%;
        text-align: center;
    }
}
.about-button {
    padding: 17.21px 23px;
}

.about-info {
    margin-bottom: 22px;
    @media (max-width: 767px) {
        display: flex;
        justify-content: center!important;
        align-items: center;
        //text-align: center;
        &__img {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    // .about-info__img
    &__img {
        img {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    // .about-info__text
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-widht: 767px) {
            text-align: center;
            font-size: rem(16);
        }
    }
}