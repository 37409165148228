.promo {
    margin-top: 20px !important;
    display: flex;    
    @media (max-width: 767px) {
        text-align: center;
        align-items: center;
        justify-content: center;
        //min-height: calc(100vh - 180px);
        &__img {
            //margin-top: 30px;
            display: flex;
            justify-content: center;
            //max-height: 270px;
        }
    }
    //margin-bottom: 45px;
    /*@media (max-width: 767px) {
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 991px) {
        margin-bottom: 30px;
        min-height: 410px;
    }*/
    // .promo__info
    &__info {
        //padding-top: 80px;
        //align-items: center;
        display: flex;
        flex-direction: column;
        @media (max-width: 1200px) {
            //padding-top: calc(15px + (50 - 20) * ((100vw - 320px) / (1200 - 320)));
        }
        @media (max-width: 767px) {
            //padding-top: 30px;
            padding-bottom: 45px;
            .lang-list {
                justify-content: center;
            }
            &_full {
                align-items: center;
            }
        }

        &_full {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .lang-list {
            margin-top: 30px;
            display: flex;
            column-gap: 10px;
            .lang-item {
                a:hover {
                    //background: rgba(0, 0, 0, 0.1);
                    //color: #000;
                }
                &.active {
                    //display: none;
                }
                //display: block;
                img {
                    width: 48px;
                }
            }
        }
    }
    // .promo__title
    &__title {
        font-family: ProbaPro;
        font-weight: 600;
        font-size: rem(80);
        text-align: start;
        color: #11265b;
        margin-bottom: 30px;
        @media (max-width: 767px) {
            //font-size: calc(60px + (90 - 70) * ((100vw - 320px) / (1200 - 320)));
            text-align: center;
        }
    }
    // .promo__text
    &__text {
        height: 120px;
        &::before {
            //content: "- ";
        }
        font-family: "Source Code Pro";
        font-weight: normal;
        font-size: rem(40); //40
        color: #000000;
        max-width: 450px;
        @media (max-width: 1200px) {
            //font-size: calc(30px + (42 - 38) * ((100vw - 320px) / (1200 - 320)));
        }
        > span > div:first-of-type {
            display: inline !important;
        }
    }
    // .promo__img
    &__img {
        display: flex;
        align-items: center;
        img {
            width: 100%;
        }
    }
}
