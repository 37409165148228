.works {
    //min-height: (100vh);
    // .works__nav
    &__nav {
        // margin-bottom: 72px !important;
    }
    // .works__title
    &__title {
        margin-bottom: 20px;
    }
    &__list {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        .box {
            display: flex;
            align-items: center;
            //margin: 0 10px;
        }
        .works__item > * > * {
            background: #fff;
            color: #000;
            transition: all 200ms ease-in;
            cursor: pointer;
            //width: 33.3333%;
            width: 280px;
            //height: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .item__img {
                flex-wrap: nowrap;
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                img {
                    max-height: 200px;
                    max-width: 100%;
                }
            }
            .box__title {
                margin-top: 20px;
                margin-bottom: 5px;
                font-size: 21px;
                justify-self: flex-end;
            }
            .box__text {
            }
            &:hover {
                //text-decoration: underline;
                //transform: scale(105%);
                //box-shadow: 0 0 30px rgba(#11265b, 0.3);
            }
            .works__item_desc {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 20px;
                a {
                    color: #000;
                    font-size: 18px;
                    font-weight: bold;
                    text-decoration: underline;
                }
                a:hover img {
                    opacity: 0.8;
                }
                img {
                    display: block;
                    margin-bottom: 20px;
                    width: 150px;
                }
            }
        }
    }

    .works__item .flip-card-back {
        .box__title {
            //text-decoration: underline;
        }
    }
}
