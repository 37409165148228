* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 16px;
    @media screen and (max-width: 1200px) {
        font-size: 14px;
    }
    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
}
body {
    font-family: ProbaPro;
    //background: #f4f4f4;
    background: linear-gradient(-45deg, #ee765247, #f6ff0042, #23a5d51d, #0c74dc27) fixed;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// --- навигация общие стили------
.nav {
    padding: 0px calc(10px + (80 - 30) * ((100vw - 320px) / (1200 - 320))) 0px calc(10px + (80 - 30) * ((100vw - 320px) / (1200 - 320))) !important;

    //margin-bottom: calc(30px + (72 - 30) * ((100vw - 320px) / (1200 - 320))) !important;
    margin-bottom: 60px !important;

    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    font-family: ProbaPro;
    font-weight: 400;
    font-size: rem(24);
    transition: all 0.3s ease 0s;
    @media (max-width: 767px) {
        display: none;
    }
    // .nav__item
    &__item {
        a {
            padding: 5px 30px;
            text-align: center;
            color: #202020;
        }
        a:hover,
        &.active a {
            text-decoration: underline;
            color: #002366;
            transition: all 0.3s ease 0s;
        }
    }
}

// --- кнопки и изображения------
.btn {
    background: #0042bd;
    border-radius: 10px;
    text-align: center;
    font-family: ProbaPro;
    font-weight: normal;
    font-size: rem(24);
    color: #f4f4f4;
    padding: 17.21px 23px;

    &:hover {
        text-decoration: none;
        background: #0354e9;
        box-shadow: 1px 1px 1px 1px #022361;
    }
    &:active {
        background: #0354e9;
        box-shadow: 0.5px 0.5px 0.5px 0.5px #022361;
    }
}

._ibg {
    position: relative;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}

.wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 25px 25px;
}
section {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.title-block {
    font-family: ProbaPro;
    font-style: normal;
    font-weight: 600;
    font-size: rem(64);
    text-align: center;
    color: #11265b;
    margin-bottom: 30px;
    @media (max-width: 1200px) {
        //font-size: calc(50px + (64 - 50) * ((100vw - 320px) / (1200 - 320)));
    }
}

.text {
    // .text__title
    &__title {
        position: relative;
        font-family: "Source Code Pro";
        font-weight: 500;
        font-size: rem(36);
        color: #000000;
        margin-bottom: 8px;
        padding-left: 30px;
        &::before {
            position: absolute;
            left: 0;
            content: "- ";
        }
        @media (max-width: 1200px) {
            //font-size: calc(20px + (35 - 8) * ((100vw - 320px) / (1200 - 320)));
        }
    }
    // .text__block
    &__block {
        font-family: "Source Code Pro";
        font-weight: 400;
        font-size: rem(24);
        color: #202020;
        margin-bottom: 39px;
        padding-left: 30px;
        @media (max-width: 1200px) {
            //font-size: calc(17px + (24 - 13) * ((100vw - 320px) / (1200 - 320)));
        }
    }
}

.box {
    $box-title-font-size: 36px;
    $box-title-font-family: ProbaPro;
    background: #ffffff;
    border-radius: 21px;
    padding: 15px 10px;
    text-align: center;
    //box-shadow: 0 0 20px rgba(#11265b, 0.2);
    border: solid 1px rgba(#0042bd, 0.1);

    &__title {
        font-family: $box-title-font-family;
        font-size: $box-title-font-size;
        font-weight: 600;
        margin-bottom: 15px;
    }
    &__text {
        font-family: $box-title-font-family;
        font-size: 18px;
    }

    &__list {
        .box__list_item {
            text-align: center;
            img {
                max-width: 100%;
            }
        }
    }
    &__slider {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            max-height: 80px;
            border-radius: 5px;
        }
    }
}
