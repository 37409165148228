.services {
    //min-height: calc(100vh);
    // .services__nav
    &__nav {
        //margin-bottom: 72px !important;
    }
    // .services__title
    &__title {
        //margin-bottom: 30px;
    }
    // .services__info
    &__info {
        font-family: "Source Code Pro";
        font-style: normal;
        font-weight: 500;
        font-size: rem(32);
        color: #000000;
        @media (max-width: 1200px) {
            //font-size: calc(20px + (32 - 22) * ((100vw - 320px) / (1200 - 320)));
        }
    }
    // .services__text
    &__text {
        margin-top: 60px !important;
        @media (max-width: 767px) {
            margin-top: 0px !important;
            font-size: rem(25);
        }
        /*li {
            margin-bottom: 15px;
            padding-left: 40px;
            &::before {
                content: "- ";
                display: inline;
                margin-left: -40px;
            }
            @media (max-width: 767px) {
                margin-bottom: 8px;
            }
        }*/
    }
    // .services__img
    &__img {
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            margin-top: 25px;
        }
    }
}
