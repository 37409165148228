.header {
    &__list {
        display: flex;
        justify-content: end;
        li {
            margin: 0;
        }
    }
    &__logo {
        img {
            max-width: 100%;
            max-height: 88px;
        }
    }
    &__burger {
        display: none;
    }
    &__body {
        align-items: center;
    }
}

// ----------- 781px --------------------
@media (max-width: 767px) {
    body.lock {
        overflow: hidden;
    }
    .header {
        $bg-color: #fff;
        $color: #202020;
        //position: fixed;
        /*top: 0;
        left: 0;
        right: 0;
        z-index: 50;*/
        //box-shadow: 0 0 3px #000;

        /*&:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $bg-color;
            z-index: 2;
            background: linear-gradient(-45deg, lighten(#ee7652, 43%), lighten(#f6ff00, 43%), lighten(#23a5d5, 43%), lighten(#0c74dc, 43%)) fixed;
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
        }*/

        &__none {
            text-align: right;
        }
        &__menu {
            //padding: 120px 0 0 0;
            z-index: 2;
            position: fixed;
            top: -110%;
            left: 0;
            width: 100%;
            height: 100%;
            //background: #c9d9f8;
            transition: all 0.3s;
            background: linear-gradient(-45deg, lighten(#ee7652, 40%), lighten(#f6ff00, 40%), lighten(#23a5d5, 40%), lighten(#0c74dc, 40%));
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
        }
        &__menu.active {
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s;
        }
        &__body {
            //padding: 12px 25px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__burger.active {
            span {
                height: 0;
            }
            &:before {
                top: 60%;
                transform: rotate(45deg);
                transition: all 0.3s;
            }
            &:after {
                top: 60%;
                transform: rotate(-45deg);
                transition: all 0.3s;
            }
        }
        &__burger {
            transform: translate3d(0, 0, 0);
            display: inline-flex;
            position: relative;
            width: 30px;
            height: 20px;
            z-index: 3;
            span {
                top: 45%;
                background: $color;
                position: absolute;
                width: 100%;
                height: 2px;
                transition: all 0.3s;
            }
            &:before,
            &:after {
                content: "";
                background: $color;
                position: absolute;
                width: 100%;
                height: 2px;
                transition: all 0.3s;
            }
            &:before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
        }

        &__list {
            flex-direction: column;
            li {
                text-align: start;
                margin: 0 0 10px 10px;
                padding: 3px;
                text-align: center;
                &.nav-item a {
                    font-size: 25px;
                    //color: $color;
                }
            }
        }
        &__logo {
            flex: 0 0 calc(160px + (190 - 160) * ((100vw - 320px) / (767 - 320)));
            overflow: hidden;
            position: relative;
            z-index: 3;
            img {
                width: 100%;
            }
        }
    }
}

// ----------- header-burger 781px --------------------
.nav-item {
    padding: 0;
    font-family: ProbaPro;
    font-weight: normal;
    font-size: rem(22);
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    transition: all 0.05s;

    a {
        display: block;
        color: #202020;
        padding: 15px 28px; //35px;
        border-radius: 10px;
        text-align: center;
        font-family: ProbaPro;
        font-weight: normal;
        &:active {
            background: #0253e9;
        }
    }
    a:hover,
    &.active a {
        color: #fcff6b;
        //transition: all 0.2s ease 0s;
        background: #0042bd;

        //font-size: rem(24);

        /*@media (max-width: 1200px) {
            padding: 12px calc(9px + (30 - 15) * ((100vw - 320px) / (1200 - 320)));
        }*/
    }
}
