.contacts {
    //min-height: calc(100vh);
    // .contacts__title
    &__title {
        //margin-bottom: 27px;
    }
    // .contacts__main
    &__main {
    }
    // .contacts__form
    &__form {
        //padding: 20px 0 0 150px;
        //min-height: 317px;
        //margin-bottom: 41px;
        display: flex;
        flex-direction: column;
        //justify-content: space-around;
        @media (max-width: 1200px) {
            //padding: calc(20px + (55 - 20) * ((100vw - 320px) / (1200 - 320))) 0 0 calc(10px + (150 - 10) * ((100vw - 320px) / (1200 - 320)));
        }
    }
    // .contacts__info
    &__info {
        //min-height: 224px;
        background: #ffffff;
        box-shadow: 2px 3px 4px #ededed;
        border-radius: 11px;
        padding: 25px;
    }
    .box {
        text-align: left;
    }
    a {
        color: #0d6efd;
        &:hover {
            text-decoration: underline;
        }
    }
}
.form-block {
    // .form-block__button
    &__button {
        //margin-top: 25px;
    }
}
.contacts-button {
    padding: 16px 28.4px;
}
.form-block {
    display: flex;
    align-items: center;
    justify-content: center;
    // .form-block__line
    &__line {
        max-width: 70%;
        font-family: "Source Code Pro";
        font-weight: normal;
        font-size: rem(24);
        text-align: start;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
    }
    // .form-block__label
    &__label {
        color: #000000;
        //margin-bottom: 17px;
    }
    // .form-block__input
    &__input {
        border: none;
        display: block;
        min-width: 320px;
        min-height: 45px;
        background: #fff;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
        border-radius: 9px;
        margin: 17px 0;
    }
    input::placeholder {
        opacity: 0.2;
        padding-left: 10px;
        font-size: rem(20);
    }

    // .form-block__img
    &__img {
        justify-content: center;
        align-items: center;
        display: flex;
        img {
            width: 100%;
            margin-top: 45px;
        }
        @media (max-width: 767px) {
            //display: none;
        }
    }
}
.contacts-info {
    // .contacts-info__title
    &__title {
        font-family: ProbaPro;
        font-weight: 600;
        font-size: rem(36);
        text-align: center;
        color: #202020;
        margin: 0 0 14px 0;
    }
    // .contacts-info__block-contacts
    &__block-contacts {
        padding: 0 0 0 20px;

        li {
            margin: 0 17px 0 70px;
            text-align: start;
            @media (max-width: 1200px) {
                margin-left: calc(15px + (60 - 35) * ((100vw - 320px) / (1200 - 320)));
            }
            @media (max-width: 998px) {
                text-align: center;
                margin: 0 0 25px 0;
            }
        }
    }
}
.block-contacts {
    color: #000000;

    // .block-contacts__title
    &__title {
        font-family: "Source Code Pro";
        font-weight: 600;
        font-size: rem(24);
        text-align: start;
        margin: 0 0 10px 0;
        @media (max-width: 1200px) {
            //font-size: calc(16px + (8 - 3) * ((100vw - 320px) / (1200 - 320)));
        }
        @media (max-width: 1200px) {
            text-align: center;
        }
    }
    // .block-contacts__text
    &__text {
        font-family: ProbaPro;
        font-weight: normal;
        font-size: rem(18);
        text-align: start;
        @media (max-width: 1200px) {
            text-align: center;
        }
    }
}
