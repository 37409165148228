@function rem($value) {
    $remValue: ($value / 16) + rem;
    @return $remValue;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* The typing effect */
/*@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}*/

/* The typewriter cursor effect */
/*@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: orange;
    }
}*/

/*@mixin typing($seconds: 2s) {
    overflow: hidden;
    border-right: 0.15em solid rgba(orange, 0.3);
    white-space: nowrap;
    width: 0;
    animation: typing $seconds steps(20, end) forwards;
}*/

.marquee-wrapper {
    overflow: hidden;
}

.marquee {
    display: flex;
    animation-name: marquee;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
    transform: translateX(0);
    img {
        padding: 1em;
    }
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.linear-wipe {
    text-align: center;
    
    background: linear-gradient(to right,#11265b 20%, rgb(85, 85, 0) 40%, rgb(85, 85, 0) 60%, #11265b 80%);
    background-size: 200% auto;
    
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    animation: shine 3s linear infinite;
    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }
  }