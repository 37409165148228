@font-face{
	font-family: ProbaPro;
	font-dislpay: swap;
	src: url("../fonts/ProbaPro-Light.ttf") format("truetype");
	font-weight:300;
	font-style: normal	
}
@font-face{
	font-family: ProbaPro;
	font-dislpay: swap;
	src: url("../fonts/ProbaPro-Medium.ttf") format("truetype");
	font-weight:500;
	font-style: normal
}
@font-face{
	font-family: ProbaPro;
	font-dislpay: swap;
	src: url("../fonts/ProbaPro-Regular.ttf") format("truetype");
	font-weight:400;
	font-style: normal
}
@font-face{
	font-family: ProbaPro;
	font-dislpay: swap;
	src: url("../fonts/ProbaPro-SemiBold.ttf") format("truetype");
	font-weight:600;
	font-style: normal
}
